import React from 'react';
import { Container } from 'react-bootstrap';

const AboutUs = () => {
  return (
    <Container className="py-5" >
      <h1 className="aboutcontainer mb-4" id="aboutus">About Us</h1>
      <p>Welcome to our coaching center! We are dedicated to providing high-quality education and empowering our students to achieve their academic goals.</p>
      <p>Meet the Owner:</p>
      <div className="owner-details">
        <img src="images\WhatsApp_Image_2023-09-08_at_7.16.15_PM.png" alt="Owner" className="owner-image" />
        <div className="owner-info">
          <h4>Madan Mohan</h4>
          <p>Founder</p>
          <p>With over 20 years of teaching experience, Madan Mohan brings a wealth of knowledge and expertise to our coaching center. His passion for education and commitment to student success have been the driving force behind our institution's growth and success.</p>
        </div>
      </div>
    </Container>
  );
}

export default AboutUs;
