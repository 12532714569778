import React from 'react';
import { Image } from 'react-bootstrap';

function Home(){
  return (
    <div class="home-container"  id="home">
      <div class="home-content">
      <h1>Welcome </h1>
      <p>Our vision is to be a leading coaching center, fostering academic achievement and personal growth. We're dedicated to equipping students from <b>8th to 12th grade </b> with the skills to excel in <b>NEET, JEE, KCET, PUC, CBSE, and ICSE.</b> Additionally, we aim to enhance <b>Spoken English</b>, offer expert guidance in subjects like <b>Accounts, Economics, and Business Studies</b>, and provide comprehensive courses in <b>Java, Python, SAP, AWS, Devops, Data Science, Testing Tools, Advanced Excel, CAT, GRE and GMAT.</b> Through exceptional educators and tailored programs, we're committed to nurturing bright minds, preparing them to excel in their chosen paths and contribute positively to society.</p>
      </div>
      <div class="home-image">
        <Image className="img" src="../images/1.png" alt="coaching-centre" fluid />
      </div>
    </div>
  );
};

export default Home;