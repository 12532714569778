const allcourses = [
  {
    name: 'NEET',
    imgSrc: './images/512x512bb.jpg',
    description: 'NEET (UG) is one of the toughest medical entrance examinations conducted in India. National Eligibility cum Entrance Test (NEET)is conducted by the National Testing Agency (NTA) for admission to undergraduate (MBBS/BDS/Ayush Courses).',
    
  },
  {
    name: 'JEE',
    imgSrc: './images/4pune-jee-main-results-decla-1599551417.jpg',
    description: 'The JEE (Joint Entrance Examination) is an entrance exam for admission into various engineering colleges across the country.it is your gateway to the prestigious IITs, NITs, and Central Funded Technical Institutes (CFTIs).',
    
  },
  {
    name: 'KCET',
    imgSrc: './images/kcetanswerkey-1649144080.jpg',
    description: 'Karnataka Common Entrance Test, also known as KCET, is a state-level entrance exam conducted by Karnataka Examination Authority (KEA). This entrance exam is organised to provide eligible candidates admission to different undergraduate courses in Karnataka.',
    
  },
  {
    name: 'CAT',
    imgSrc: './images/Untitled-design-1-1-1024x576.png',
    description: 'CAT is Common Admission Test or CAT is a computer-based aptitude test. It is an aptitude exam designed to take admission to management courses offered by some top management institutes in India. It is one of the most renowned and toughest entrance exams in India. ',
    
  },
  {
    name: 'GMAT',
    imgSrc: './images/GMAT-Exam.png',
    description: 'The GMAT exam is designed to test skills that are highly important to business and management programs. It assesses analytical writing and problem-solving abilities, along with the data sufficiency, logic, and critical reasoning skills that are vital to real-world business and management success.',
    
  },
  {
    name: 'GRE',
    imgSrc: './images/gre.jpg',
    description: "The GRE General Test measures verbal reasoning, quantitative reasoning, critical thinking and analytical writing skills that have been developed over a long period of time and are required for success in today's demanding programs.",
    
  },
  
  {
    name: 'Java',
    imgSrc: './images/java_original_wordmark_logo_icon_146459.png',
    description: 'Java is a multi-platform, object-oriented, and network-centric language that can be used as a platform in itself. It is a fast, secure, reliable programming language for coding everything from mobile apps and enterprise software to big data applications and server-side technologies.',
    
  },
  {
    name: 'Python',
    imgSrc: './images/Python-logo-notext.svg.png',
    description: 'Python is an interpreted, object-oriented, high-level programming language with dynamic semantics. Its high-level built in data structures, combined with dynamic typing and dynamic binding, make it very attractive for Rapid Application Development, as well as for use as a scripting or glue language to connect existing components together.',

  },
  {
    name: 'Advanced Excel',
    imgSrc: './images/microsoft_excel_alt_macos_bigsur_icon_189978.png',
    description: 'This course goes beyond the basics and delves into more complex features and functions that Excel offers. Participants can expect to learn about advanced formulas and functions, data analysis techniques, pivot tables, data visualization through charts and graphs, automation using macros, collaboration features, and advanced data manipulation tools.',
    
  },
  {
    name: 'SAP',
    imgSrc: './images/SAP_SE-Logo.wine.png',
    description: 'SAP usually stands for Systems, Applications, and Products in Data Processing. It usually combines instruction in human resource software management, database management, and business training. You can get SAP Certification as a part of a degree or even as a stand-alone course. SAP is famous for its Enterprise Resource Planning and Data Management Programs.',
    
  },
  {
    name: 'AWS',
    imgSrc: './images/2560px-Amazon_Web_Services_Logo.svg.png',
    description: 'AWS (Amazon Web Services) is a comprehensive, evolving cloud computing platform provided by Amazon that includes a mixture of infrastructure-as-a-service (IaaS), platform-as-a-service (PaaS) and packaged-software-as-a-service (SaaS) offerings. AWS services can offer an organization tools such as compute power, database storage and content delivery services.',
    
  },
  {
    name: 'DevOps',
    imgSrc: './images/png-transparent-cloud-symbol-devops-business-process-software-development-process-software-deployment-information-technology-implementation-cloud-computing.png',
    description: 'DevOps is a set of practices, tools, and a cultural philosophy that automate and integrate the processes between software development and IT teams. It emphasizes team empowerment, cross-team communication and collaboration, and technology automation.',
    
  },
  {
    name: 'Data Science',
    imgSrc: './images/science.png',
    description: "Data science combines math and statistics, specialized programming, advanced analytics, artificial intelligence (AI), and machine learning with specific subject matter expertise to uncover actionable insights hidden in an organization’s data. These insights can be used to guide decision making and strategic planning.",
    
  },
  {
    name: 'Testing Tools',
    imgSrc: './images/Capture.PNG',
    description: "Software Testing tools are the tools that are used for the testing of software. Software testing tools are often used to assure firmness, thoroughness, and performance in testing software products. ",
    
  },
  {
    name: 'Spoken English',
    imgSrc: './images/Capture2.PNG',
    description: "The ways in which the English language is transmitted through a conventional system of sounds. Compare to written English.",
    
  },
];

export default allcourses;