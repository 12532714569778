import React from 'react';
import { Container} from 'react-bootstrap';

const Admission = () => {
  return (
    <section className="admission-section" id="admission">
      <Container >
            <h1 className="mb-4">Admission</h1>
            <p>Welcome to our coaching center! We're excited to have you on board. Here's how you can get started:</p>
            <p className="admission-steps">
              <li>Visit our center located at Brilliant Academy, Marathahalli, M.T.K Reddy Building, Beside Sangeeta Mobiles.</li>
              <li>Contact us at the following numbers to schedule an appointment:
                <p>
                  <p>📞: <a href="tel:+91 9845555716">+91 9845555716</a></p>
                  <p>📞: <a href="tel:+91 9900213083">+91 9900213083</a></p>
                </p>
              </li>
              <li>During your visit, We will guide you through the admission process and provide you with all necessary information.</li>
              </p>
      </Container>
    </section>
  );
}

export default Admission;
