// BranchesComponent.jsx
import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import ourbranches from '../states/ourbranches';

const BranchesComponent = () => {
  return (
    <Container className="branches-container" >
      <h2 id="branch">Branches </h2 >
      <Row>
        {ourbranches.map((branch, index) => (
          <Col md={4} key={index}>
            <Card className="branch-card">
              <Card.Body>
                <Card.Title>{branch.name} Branch</Card.Title>
                <Card.Text>
                  <strong>Location:</strong>{' '}
                  <a href={branch.location} target="_blank" rel="noopener noreferrer">
                    View on Google Maps
                  </a>
                </Card.Text>
                <Card.Text>
                  <strong>Address:</strong> {branch.address}
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default BranchesComponent;

