import React from 'react';
import { Container, Carousel } from 'react-bootstrap';

const Gallery = () => {
  return (
    <Container fluid style={{ paddingTop: "15px", height: '70vh', backgroundColor: "#f8f9fa"}}>
      <Carousel data-bs-theme="dark"  interval="5000" slide="true" pause="true" style={{ maxWidth: '300px', margin: 'auto' }}>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.18.23 PM.jpeg"
            alt="First slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.19.43 PM (1).jpeg"
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.19.43 PM.jpeg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.19.44 PM.jpeg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.19.45 PM.jpeg"
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="images\WhatsApp Image 2023-09-08 at 7.19.44 PM (1).jpeg"
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </Container>
  );
};

export default Gallery;