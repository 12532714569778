const ourbranches = [
    {
      name:'Bengaluru',
      location: 'https://www.google.com/maps/place/Brilliant+Academy/@12.9571189,77.7006924,21z/data=!4m16!1m9!3m8!1s0x3bae1334129c1523:0x8cb028f0a2de3de6!2sBrilliant+Academy!8m2!3d12.9571189!4d77.7006924!9m1!1b1!16s%2Fg%2F1ptxm8vqd!3m5!1s0x3bae1334129c1523:0x8cb028f0a2de3de6!8m2!3d12.9571189!4d77.7006924!16s%2Fg%2F1ptxm8vqd?entry=ttuhttps://www.google.com/maps/place/Brilliant+Academy/@12.9571189,77.7006924,21z/data=!4m16!1m9!3m8!1s0x3bae1334129c1523:0x8cb028f0a2de3de6!2sBrilliant+Academy!8m2!3d12.9571189!4d77.7006924!9m1!1b1!16s%2Fg%2F1ptxm8vqd!3m5!1s0x3bae1334129c1523:0x8cb028f0a2de3de6!8m2!3d12.9571189!4d77.7006924!16s%2Fg%2F1ptxm8vqd?entry=ttu',
      address: 'Brilliant Academy, Marathahalli, M.T.K Reddy Building, Beside Sangeeta Mobiles.',
    },
    {
      name:'Hyderabad',
      location: '',
      address: '',
    },
  ]

export default ourbranches;