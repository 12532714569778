import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';

const Footer = () => {
  return (
    <footer className="footer text-dark py-5">
      <Container>
        <Row>
          <Col md={6}>
            <h4 className="mb-4">Navigation</h4>
            <ul className="list-unstyled">
              <li><a href="#home" className="text-dark">Home</a></li>
              <li><a href="#courses" className="text-dark">Courses</a></li>
              <li><a href="#admission" className="text-dark">Admission</a></li>
              <li><a href="#aboutus" className="text-dark">About Us</a></li>
            </ul>
          </Col>
          <Col md={6}>
            <h4 className="mb-4">Contact Us</h4>
            <p>Phone 1: <a href="tel:+91 9845555716" className="text-dark">+91 9845555716</a></p>
            <p>Phone 2: <a href="tel:+91 9900213083" className="text-dark">+91 9900213083</a></p>
            <p className="address">Brilliant Academy, Marathahalli, M.T.K Reddy Building, Beside Sangeeta Mobiles.</p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
