import React from "react";
import { Container } from 'react-bootstrap';
import allcourses from '../states/coursedetails'

const Course = ({ name, imgSrc, description }) => {
  return (
    <div className="course-item">
      <img src={imgSrc} alt={name} />
      <div className="course-name">{name}</div>
      <div className="course-description">{description}</div>
    </div>
  );
};


const Courses = () => {

  const scrollLeft = () => {
    const courseContainer = document.querySelector('.course-container');
    courseContainer.scrollLeft -= 200;
  };

  const scrollRight = () => {
    const courseContainer = document.querySelector('.course-container');
    courseContainer.scrollLeft += 200;
  };

  return (
    <Container fluid className="courses-section"  id="courses">
        <h1 className="courses-heading">Courses</h1>
        <div className="course-section">
          <div className="course-container">
            {allcourses.map((course, index) => (
              <Course key={index} {...course} />
            ))}
          </div>
          <div className="nav-buttons left-button">
            <button className="nav-button" onClick={scrollLeft}>
              <i className="fas fa-chevron-left"></i>
            </button>
          </div>
          <div className="nav-buttons right-button">
            <button className="nav-button" onClick={scrollRight}>
              <i className="fas fa-chevron-right"></i>
            </button>
          </div>
        </div>
      </Container>
  );
}


export default Courses;
