import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

const imageStyle = {
  height: "85px",
  width: "100px",
  paddingRight: "10px",
}

function navbar() {
  return (
    <Navbar className='navbar fixed-top' collapseOnSelect expand="md">
      <Container> 
        <Navbar.Brand href="#aboutus"><img src="..\images\brilliant-acad_logo.png" alt="logo" style={imageStyle}/>Brilliant Academy</Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
          <Nav.Link href="#home">Home</Nav.Link>
          <Nav.Link href="#courses"> Courses </Nav.Link>
          <Nav.Link href="#admission">Admission</Nav.Link>
          <Nav.Link href="#aboutus">About Us</Nav.Link>  
          <Nav.Link href="#branch">Branch</Nav.Link>  

          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default navbar;