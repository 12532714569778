import React from 'react';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/footer';
import Courses from './components/Courses';
import Admission from './components/admission';
import AboutUs from './components/aboutus';
import Gallery from './components/gallery';
import BranchesComponent from './components/branches';


import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';


function App() {

  return (
    <div>
      <Navbar  /> 
      <Home />
      <Courses />
      <Admission />
      <AboutUs />
      <Gallery />
      <BranchesComponent />
      <Footer />
    </div>
  );
}

export default App;
